import React, { useState } from 'react';
import { server } from '../../../utils/Constantes';

const BuscarOfertasForm = () => {
    const [formData, setFormData] = useState({
        origen: '',
        personas: '',
        destino: '',
        fecha_ida: '',
        fecha_vuelta: '',
        tipo: '',
        company: '',
        imagen: '',
        galeria: '',
        descripcion: '',
        urlVuelos: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch(`${server}/findPlanAutomatico`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        const result = await response.json();
        console.log(result);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label>Origen:</label>
                <input type="text" name="origen" value={formData.origen} onChange={handleChange} />
            </div>
            <div>
                <label>Personas:</label>
                <input type="number" name="personas" value={formData.personas} onChange={handleChange} />
            </div>
            <div>
                <label>Destino:</label>
                <input type="text" name="destino" value={formData.destino} onChange={handleChange} />
            </div>
            <div>
                <label>Fecha de Ida:</label>
                <input type="date" name="fecha_ida" value={formData.fecha_ida} onChange={handleChange} />
            </div>
            <div>
                <label>Fecha de Vuelta:</label>
                <input type="date" name="fecha_vuelta" value={formData.fecha_vuelta} onChange={handleChange} />
            </div>
            <div>
                <label>Tipo:</label>
                <input type="text" name="tipo" value={formData.tipo} onChange={handleChange} />
            </div>
            <div>
                <label>Compañía:</label>
                <input type="text" name="company" value={formData.company} onChange={handleChange} />
            </div>
            <div>
                <label>Imagen:</label>
                <input type="text" name="imagen" value={formData.imagen} onChange={handleChange} />
            </div>
            <div>
                <label>Galería:</label>
                <input type="text" name="galeria" value={formData.galeria} onChange={handleChange} />
            </div>
            <div>
                <label>Descripción:</label>
                <input type="text" name="descripcion" value={formData.descripcion} onChange={handleChange} />
            </div>
            <div>
                <label>URL de Vuelos:</label>
                <input type="text" name="urlVuelos" value={formData.urlVuelos} onChange={handleChange} />
            </div>
            <button type="submit">Buscar Ofertas</button>
        </form>
    );
};

export default BuscarOfertasForm;
