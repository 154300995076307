export const aeropuertos = [
    "Abu Dhabi (Emiratos Árabes Unidos), AUH",
    "Batten (Azerbaiyán), AZI",
    "Jebel Dhana (Emiratos Árabes Unidos),",
    "Al Ain (Emiratos Árabes Unidos), AAN",
    "Al Ddafra (Emiratos Árabes Unidos), DHF",
    "Sweihan (Emiratos Árabes Unidos)",
    "Dubai (Emiratos Árabes Unidos), DXB",
    "Al Minhad (Emiratos Árabes Unidos), NHD",
    "Al Maktoum (Emiratos Árabes Unidos), DWC",
    "Fujairah (Emiratos Árabes Unidos), FJR",
    "Ras Al Khaimah (Emiratos Árabes Unidos), RKT",
    "Sharjah (Emiratos Árabes Unidos), SHJ",
    "Herat (Afganistán), HEA",
    "Bagram (Afganistán), OAI",
    "Hamid Karzai (Afganistán), KBL",
    "Kandahar (Afganistán), KDH",
    "Budapest (Hungria), BUD",
    "Mazar I Sharif (Afganistán), MZR",
    "Camp Bastion (Afganistán), OAZ",
    "Ciudad de Saint John's (Antigua y Barbuda), ANU",
    "Clayton J Lloyd (Anguila), AXA",
    "Tirana (Albania), TIA",
    "Ereván (Armenia), EVN",
    "Benguela (Angola), BUG",
    "Cabinda (Angola), CAB",
    "Luanda (Angola), LAD",
    "Rosario (Argentina), ROS",
    "Buenos Aires (Argentina), AEP",
    "Córdoba (Argentina), COR",
    "Buenos Aires (Argentina), EPA",
    "Buenos Aires (Argentina), EZE",
    "Mendoza (Argentina), MDZ",
    "Termas de Río Hondo (Argentina), RHD",
    "San Miguel de Tucumán (Argentina), TUC",
    "Villa Mercedes (Argentina), VME",
    "Resistencia (Argentina), RES",
    "Puerto Iguazú (Argentina), IGR",
    "Salta (Argentina), SLA",
    "Arraiján (Panamá), ARR",
    "Ushuaia (Argentina), USH",
    "Olavarría (Argentina), OVR",
    "Neuquén (Argentina), NQN",
    "San Carlos de Bariloche (Argentina), BRC",
    "San Martín de los Andes (Argentina), CPC",
    "Wiener Neustadt (Austria)",
    "Graz (Austria), GRZ",
    "Innsbruck (Austria), INN",
    "Klagenfurt (Austria), KLU",
    "Linz (Austria), LNZ",
    "Salzburgo (Austria), SZG",
    "Viena (Austria), VIE",
    "Brisbane (Australia), BNE",
    "Broken Hill (Australia), BHQ",
    "Geraldton (Australia), GET",
    "Glenormiston (Australia), GLM",
    "Lakefield (Australia), LFP",
    "Avalon (Australia), AVV",
    "Melbourne (Australia), MEL",
    "Nullabor Motel (Australia), NUR",
    "Adelaida (Australia), ADL",
    "Learmonth (Australia), LEA",
    "Perth (Australia), PER",
    "Sídney (Australia), SYD",
    "Oranjestad (Aruba), AUA",
    "Bakú (Azerbaiyán), GYD",
    "Ganja (Azerbaiyán), KVD",
    "Lankaran (Azerbaiyán), LLK",
    "Sarajevo (Bosnia y Herzegovina), SJJ",
    "Tuzla (Bosnia y Herzegovina), TZL",
    "Bridgetown (Barbados)",
    "Daca (Bangladés), DAC",
    "Sylhet (Bangladés), ZYL",
    "Amberes (Bélgica), ANR",
    "Kleine Brogel (Bélgica)",
    "Bruselas (Bélgica), BRU",
    "Charleroi (Bélgica), CRL",
    "Florennes (Bélgica)",
    "Wevelgem (Bélgica), KJK",
    "Lieja (Bélgica), LGG",
    "Ostend-Bruges (Bélgica), OST",
    "Arly (Burkina Faso), ARL",
    "Uagadugú (Burkina Faso), OUA",
    "Bobo Dioulasso (Burkina Faso), BOY",
    "Burgas (Bulgaria), BOJ",
    "Gorna Oryahovitsa (Bulgaria), GOZ",
    "Plovdiv (Bulgaria), PDV",
    "Sofía (Bulgaria), SOF",
    "Varna (Bulgaria), VAR",
    "Manama (Baréin), BAH",
    "Bujumbura (Burundi), BJM",
    "Cotonú (Benín), COO",
    "Bermudas (Bermudas), BDA",
    "Brunei (Brunéi), BWN",
    "Santa Cruz de la Sierra (Bolivia), SRZ",
    "Cochabamba (Bolivia), CBB",
    "La Paz (Bolivia), LPB",
    "Potosí (Bolivia), POI",
    "Sucre (Bolivia), SRE",
    "Tarija (Bolivia), TJA",
    "Trinidad (Bolivia), TDD",
    "Barcelona (Venezuela), BLA",
    "Caracas (Venezuela), CCS",
    "Ciudad Bolívar (Venezuela), CBL",
    "El Vigía (Venezuela), VIG",
    "La Fría (Venezuela), LFR",
    "Maracaibo (Venezuela), MAR",
    "Porlamar (Venezuela), PMV",
    "San Antonio del Táchira (Venezuela), SVZ",
    "San Fernando de Apure (Venezuela), SFD",
    "Valencia (Venezuela), VLN",
    "Valera (Venezuela), VLV",
    "Zaraza (Venezuela), PYH",
    "Culiacán (México), CUL",
    "Durango (México), DGO",
    "La Paz (México), LAP",
    "Los Cabos (México), SJD",
    "Mazatlán (México), MZT",
    "Mexicali (México), MXL",
    "Monterrey (México), MTY",
    "Oaxaca (México), OAX",
    "Puerto Vallarta (México), PVR",
    "Tijuana (México), TIJ",
    "Tuxtla Gutiérrez (México), TGZ",
    "Veracruz (México), VER",
    "Villahermosa (México), VSA",
    "Zihuatanejo (México), ZIH",
    "Aguascalientes (México), AGU",
    "Acapulco (México), ACA",
    "Campeche (México), CPE",
    "Cancún (México), CUN",
    "Chetumal (México), CTM",
    "Chihuahua (México), CUU",
    "Ciudad del Carmen (México), CME",
    "Ciudad Juárez (México), CJS",
    "Ciudad Obregón (México), CEN",
    "Ciudad Victoria (México), CVM",
    "Colima (México), CLQ",
    "Cozumel (México), CZM",
    "Cuernavaca (México), CVJ",
    "Culiacán (México), CUL",
    "Durango (México), DGO",
    "Guadalajara (México), GDL",
    "Hermosillo (México), HMO",
    "Huatulco (México), HUX",
    "Isla Mujeres (México), ISJ",
    "La Paz (México), LAP",
    "León (México), BJX",
    "Los Cabos (México), SJD",
    "Manzanillo (México), ZLO",
    "Mazatlán (México), MZT",
    "Mexicali (México), MXL",
    "Minatitlán (México), MTT",
    "Monterrey (México), MTY",
    "Morelia (México), MLM",
    "Oaxaca (México), OAX",
    "Piedras Negras (México), PDS",
    "Poza Rica (México), PAZ",
    "Puebla (México), PBC",
    "Puerto Escondido (México), PXM",
    "Puerto Vallarta (México), PVR",
    "Querétaro (México), QRO",
    "Reynosa (México), REX",
    "Saltillo (México), SLW",
    "San Cristóbal de las Casas (México), SZT",
    "San Felipe (México), SFE",
    "Tampico (México), TAM",
    "Tijuana (México), TIJ",
    "Toluca (México), TLC",
    "Tuxtla Gutiérrez (México), TGZ",
    "Veracruz (México), VER",
    "Villahermosa (México), VSA",
    "Zacatecas (México), ZCL",
    "Zihuatanejo (México), ZIH",
    "Zitácuaro (México), ZIC",
    "Quito (Ecuador), UIO",
    "San Cristóbal (Ecuador), SCY",
    "La Habana (Cuba), HAV",
    "Cienfuegos (Cuba), CFG",
    "Camagüey (Cuba), CMW",
    "Holguín (Cuba), HOG",
    "Nueva Gerona (Cuba), GER",
    "Santiago de Cuba (Cuba), SCU",
    "Varadero (Cuba), VRA",
    "Georgetown (Guyana), GEO",
    "Boa Vista (Brasil), BVB",
    "Boavista (Cabo Verde), BVC",
    "São Filipe (Cabo Verde), SFL",
    "Sal (Cabo Verde), SID",
    "Espargos (Cabo Verde), SID",
    "Praia (Cabo Verde), RAI",
    "Mamoudzou (Mayotte), DZA",
    "Mtsamboro (Mayotte), MWA",
    "Dzaoudzi (Mayotte), DZA",
    "Panama City (Panamá), BLB",
    "Dar es Salaam (Tanzania), DAR",
    "Dodoma (Tanzania), DOD",
    "Kilimanjaro (Tanzania), JRO",
    "Mwanza (Tanzania), MWZ",
    "Zanzíbar (Tanzania), ZNZ",
    "La Habana (Cuba), HAV",
    "Camagüey (Cuba), CMW",
    "Holguín (Cuba), HOG",
    "Manzanillo (Cuba), MZO",
    "Santa Clara (Cuba), SNU",
    "Santiago de Cuba (Cuba), SCU",
    "Varadero (Cuba), VRA",
    "Praia (Cabo Verde), RAI",
    "Los Ángeles (Estados Unidos), LAX",
    "San Francisco (Estados Unidos), SFO",
    "Las Vegas (Estados Unidos), LAS",
    "Denver (Estados Unidos), DEN",
    "Chicago (Estados Unidos), ORD",
    "Houston (Estados Unidos), IAH",
    "Miami (Estados Unidos), MIA",
    "Orlando (Estados Unidos), MCO",
    "Nueva York (Estados Unidos), JFK",
    "Washington, D.C. (Estados Unidos), DCA",
    "Atlanta (Estados Unidos), ATL",
    "Dallas (Estados Unidos), DFW",
    "Seattle (Estados Unidos), SEA",
    "Los Ángeles (Estados Unidos), LAX",
    "San Francisco (Estados Unidos), SFO",
    "Las Vegas (Estados Unidos), LAS",
    "Denver (Estados Unidos), DEN",
    "Chicago (Estados Unidos), ORD",
    "Houston (Estados Unidos), IAH",
    "Miami (Estados Unidos), MIA",
    "Orlando (Estados Unidos), MCO",
    "Nueva York (Estados Unidos), JFK",
    "Washington, D.C. (Estados Unidos), DCA",
    "Atlanta (Estados Unidos), ATL",
    "Dallas (Estados Unidos), DFW",
    "Seattle (Estados Unidos), SEA",
    "Montevideo (Uruguay), MVD",
    "Punta del Este (Uruguay), PDP",
    "Maldonado (Uruguay), PDP",
    "Artigas (Uruguay), ATI",
    "Bella Unión (Uruguay), BUV",
    "Durazno (Uruguay), DZO",
    "Fray Bentos (Uruguay), FZB",
    "Laguna del Sauce (Uruguay), LGS",
    "Las Piedras (Uruguay), LAP",
    "Melo (Uruguay), MLZ",
    "Mercedes (Uruguay), DZO",
    "Minas (Uruguay), MVD",
    "Paysandú (Uruguay), PDU",
    "Río Branco (Uruguay), RVY",
    "Rivera (Uruguay), RVY",
    "Salto (Uruguay), STY",
    "Tacuarembó (Uruguay), TAW",
    "Treinta y Tres (Uruguay), TYT",
    "Kingstown (San Vicente y las Granadinas), SVD",
    "La Unión (El Salvador), SAL",
    "San Salvador (El Salvador), SAL",
    "Cayena (Guayana Francesa), CAY",
    "Puerto Príncipe (Haití), PAP",
    "Horta (Portugal), HOR",
    "Funchal (Portugal), FNC",
    "Ponta Delgada (Portugal), PDL",
    "Lisboa (Portugal), LIS",
    "Oporto (Portugal), OPO",
    "Santa Maria (Portugal), SMA",
    "Viana do Castelo (Portugal), VRL",
    "Aveiro (Portugal), ZAV",
    "Braga (Portugal), BGZ",
    "Beja (Portugal), BYJ",
    "Bragança (Portugal), BGC",
    "Coímbra (Portugal), CBP",
    "Covilhã (Portugal), COV",
    "Évora (Portugal), EVR",
    "Guarda (Portugal), GAI",
    "Horta (Portugal), HOR",
    "Funchal (Portugal), FNC",
    "Ponta Delgada (Portugal), PDL",
    "Lisboa (Portugal), LIS",
    "Oporto (Portugal), OPO",
    "Santa Maria (Portugal), SMA",
    "Viana do Castelo (Portugal), VRL",
    "Vila Real (Portugal), VRL",
    "Viseu (Portugal), VSE",
    "Pristina (Kosovo), PRN",
    "Tirana (Albania), TIA",
    "Albacete (España), ABC",
    "Alicante (España), ALC",
    "Almería (España), LEI",
    "Asturias (España), OVD",
    "Badajoz (España), BJZ",
    "Barcelona (España), BCN",
    "Bilbao (España), BIO",
    "Burgos (España), RJL",
    "Ceuta (España), JCU",
    "Córdoba (España), ODB",
    "Girona (España), GRO",
    "Gran Canaria (España), LPA",
    "Ibiza (España), IBZ",
    "Jerez de la Frontera (España), XRY",
    "La Coruña (España), LCG",
    "La Gomera (España), GMZ",
    "Lanzarote (España), ACE",
    "Lleida (España), ILD",
    "Logroño (España), RJL",
    "Madrid (España), MAD",
    "Mahón (España), MAH",
    "Málaga (España), AGP",
    "Melilla (España), MLN",
    "Menorca (España), MAH",
    "Murcia (España), MJV",
    "Oviedo (España), OVD",
    "Palma de Mallorca (España), PMI",
    "Pamplona (España), PNA",
    "Reus (España), REU",
    "Sabadell (España), QSA",
    "Santander (España), SDR",
    "Santiago de Compostela (España), SCQ",
    "Sevilla (España), SVQ",
    "Tenerife (España), TFS",
    "Valencia (España), VLC",
    "Valladolid (España), VLL",
    "Vigo (España), VGO",
    "Vitoria (España), VIT",
    "Zaragoza (España), ZAZ",
    "Aberdeen (Reino Unido), ABZ",
    "Barra (Reino Unido), BRR",
    "Barra (Reino Unido), BRR",
    "Belfast (Reino Unido), BFS",
    "Benbecula (Reino Unido), BEB",
    "Benbecula (Reino Unido), BEB",
    "Birmingham (Reino Unido), BHX",
    "Blackpool (Reino Unido), BLK",
    "Bournemouth (Reino Unido), BOH",
    "Brighton (Reino Unido), BSH",
    "Bristol (Reino Unido), BRS",
    "Cambridge (Reino Unido), CBG",
    "Campbeltown (Reino Unido), CAL",
    "Cardiff (Reino Unido), CWL",
    "Cardiff (Reino Unido), CWL",
    "Carlisle (Reino Unido), CAX",
    "Chennai (India), MAA",
    "Kolkata (India), CCU",
    "Mumbai (India), BOM",
    "New Delhi (India), DEL",
    "Varanasi (India), VNS",
    "Adelaida (Australia), ADL",
    "Avalon (Australia), AVV",
    "Brisbane (Australia), BNE",
    "Cairns (Australia), CNS",
    "Canberra (Australia), CBR",
    "Darwin (Australia), DRW",
    "Gold Coast (Australia), OOL",
    "Hobart (Australia), HBA",
    "Melbourne (Australia), MEL",
    "Perth (Australia), PER",
    "Sídney (Australia), SYD",
    "Pago Pago (Samoa Americana), PPG",
    "Tutuila (Samoa Americana), PPG",
    "Samoa (Samoa), FGI",
    "Afutara (Islas Salomón), AFT",
    "Aguni (Japón), AGJ",
    "Akita (Japón), AXT",
    "Aomori (Japón), AOJ",
    "Asahikawa (Japón), AKJ",
    "Chubu (Japón), NGO",
    "Fukuoka (Japón), FUK",
    "Fukue (Japón), FUJ",
    "Fukushima (Japón), FKS",
    "Hachijojima (Japón), HAC",
    "Hagi-Iwami (Japón), IWJ",
    "Hakodate (Japón), HKD",
    "Hanamaki (Japón), HNA",
    "Hateruma (Japón), HTR",
    "Hiroshima (Japón), HIJ",
    "Hyakuri (Japón), IBR",
    "Ibaraki (Japón), IBR",
    "Ishigaki (Japón), ISG",
    "Iwakuni (Japón), IWK",
    "Iwo Jima (Japón), IWO",
    "Kagoshima (Japón), KOJ",
    "Kikai (Japón), KKX",
    "Kikaiga Shima (Japón), KKX",
    "Kitadaito (Japón), KTD",
    "Kitakyushu (Japón), KKJ",
    "Kochi (Japón), KCZ",
    "Komatsu (Japón), KMQ",
    "Kumejima (Japón), UEO",
    "Kushiro (Japón), KUH",
    "Matsuyama (Japón), MYJ",
    "Memanbetsu (Japón), MMB",
    "Minami Daito (Japón), MMD",
    "Misawa (Japón), MSJ",
    "Miyako Jima (Japón), MMY",
    "Miyazaki (Japón), KMI",
    "Monbetsu (Japón), MBE",
    "Nagasaki (Japón), NGS",
    "Nagoya (Japón), NGO",
    "Naha (Japón), OKA",
    "Nakashibetsu (Japón), SHB",
    "Niigata (Japón), KIJ",
    "Obihiro (Japón), OBO",
    "Odate Noshiro (Japón), ONJ",
    "Okayama (Japón), OKJ",
    "Okinawa (Japón), OKA",
    "Osaka (Japón), ITM",
    "Oshima (Japón), OIM",
    "Rishiri (Japón), RIS",
    "Sado Shima (Japón), SDS",
    "Saga (Japón), HSG",
    "Sapporo (Japón), CTS",
    "Sendai (Japón), SDJ",
    "Shimojishima (Japón), SHI",
    "Shirahama (Japón), SHM",
    "Shonai (Japón), SYO",
    "Takamatsu (Japón), TAK",
    "Takamatsu (Japón), TAK",
    "Taramajima (Japón), TRA",
    "Tateyama (Japón), TTE",
    "Tokio (Japón), HND",
    "Tokio (Japón), NRT",
    "Tottori (Japón), TTJ",
    "Toyama (Japón), TOY",
    "Toyooka (Japón), TJH",
    "Tsushima (Japón), TSJ",
    "Ube (Japón), UBJ",
    "Wakkanai (Japón), WKJ",
    "Yamagata (Japón), GAJ",
    "Yakushima (Japón), KUM",
    "Yonaguni Jima (Japón), OGN",
    "Zamami (Japón), KOO",
    "Barbados (Barbados), BGI",
    "Bridgetown (Barbados), BGI",
    "Ogle (Guyana), OGL",
    "Estación Zárate (Argentina), OYO",
    "Buenos Aires (Argentina), AEP",
    "Buenos Aires (Argentina), EPA",
    "Buenos Aires (Argentina), EZE",
    "Buenos Aires (Argentina), EPA",
    "Bariloche (Argentina), BRC",
    "Catamarca (Argentina), CTC",
    "Córdoba (Argentina), COR",
    "Corrientes (Argentina), CNQ",
    "El Palomar (Argentina), EPA",
    "Esquel (Argentina), EQS",
    "Formosa (Argentina), FMA",
    "Iguazú (Argentina), IGR",
    "Jujuy (Argentina), JUJ",
    "La Rioja (Argentina), IRJ",
    "Malargüe (Argentina), LGS",
    "Mar del Plata (Argentina), MDQ",
    "Mendoza (Argentina), MDZ",
    "Neuquén (Argentina), NQN",
    "Olavarría (Argentina), OVR",
    "Paraná (Argentina), PRA",
    "Posadas (Argentina), PSS",
    "Puerto Madryn (Argentina), PMY",
    "Resistencia (Argentina), RES",
    "Río Cuarto (Argentina), RCU",
    "Río Gallegos (Argentina), RGL",
    "Río Grande (Argentina), RGA",
    "Rosario (Argentina), ROS",
    "Salta (Argentina), SLA",
    "San Juan (Argentina), UAQ",
    "San Luis (Argentina), LUQ",
    "San Martín de los Andes (Argentina), CPC",
    "San Miguel de Tucumán (Argentina), TUC",
    "San Rafael (Argentina), AFA",
    "Santa Fe (Argentina), SFN",
    "Santa Rosa (Argentina), RSA",
    "Santiago del Estero (Argentina), SDE",
    "Termas de Río Hondo (Argentina), RHD",
    "Trelew (Argentina), REL",
    "Ushuaia (Argentina), USH",
    "Villa Gesell (Argentina), VLG",
    "Villa Mercedes (Argentina), VME",
    "Abu Dhabi (Emiratos Árabes Unidos), AUH",
    "Al Ain (Emiratos Árabes Unidos), AAN",
    "Dubai (Emiratos Árabes Unidos), DXB",
    "Sharjah (Emiratos Árabes Unidos), SHJ",
    "Fujairah (Emiratos Árabes Unidos), FJR",
    "Ras Al Khaimah (Emiratos Árabes Unidos), RKT",
    "Al Ddafra (Emiratos Árabes Unidos), DHF",
    "Al Minhad (Emiratos Árabes Unidos), NHD",
    "Al Maktoum (Emiratos Árabes Unidos), DWC",
    "Sweihan (Emiratos Árabes Unidos)",
    "Bakú (Azerbaiyán), GYD",
    "Ganja (Azerbaiyán), KVD",
    "Lankaran (Azerbaiyán), LLK",
    "Barbados (Barbados), BGI",
    "Chaguaramas (Trinidad y Tobago), POS",
    "Paria (Trinidad y Tobago), SFG",
    "Paris (Francia), CDG",
    "Paris (Francia), ORY",
    "Scarborough (Trinidad y Tobago), TAB",
    "Sevilla (España), SVQ",
    "Valencia (España), VLC",
    "Yerevan (Armenia), EVN",
    "Ganja (Azerbaiyán), KVD",
    "Nakhchivan (Azerbaiyán), NAJ",
    "Kuando Kubango (Angola), CBT",
    "Kuito (Angola), SVP",
    "Luanda (Angola), LAD",
    "Cabinda (Angola), CAB",
    "Dundo (Angola), DUE",
    "Lubango (Angola), SDD",
    "Mbanza Congo (Angola), SSY",
    "Menongue (Angola), SPP",
    "Namibe (Angola), MSZ",
    "Ondjiva (Angola), VPE",
    "Soio (Angola), SZA",
    "Soyo (Angola), SZA",
    "Kuito (Angola), SVP",
    "Luanda (Angola), LAD",
    "Abidjan (Costa de Marfil), ABJ",
    "Abuja (Nigeria), ABV",
    "Accra (Ghana), ACC",
    "Addis Abeba (Etiopía), ADD",
    "Agadir (Marruecos), AGA",
    "Aiyura Valley (Papúa Nueva Guinea), AYU",
    "Aksum (Etiopía), AXU",
    "Alejandría (Egipto), ALY",
    "Alicante (España), ALC",
    "Asmara (Eritrea), ASM",
    "Assiut (Egipto), ATZ",
    "Asuán (Egipto), ASW",
    "Bahariya Oasis (Egipto), BCE",
    "Bahrein (Baréin), BAH",
    "Bandar Abbas (Irán), BND",
    "Bandar-e Anzali (Irán), BXR",
    "Banjul (Gambia), BJL",
    "Beira (Mozambique), BEW",
    "Beirut (Líbano), BEY",
    "Béni Abbès (Argelia), TMR",
    "Bengasi (Libia), BEN",
    "Berlín (Alemania), BER",
    "Blantyre (Malaui), BLZ",
    "Bosaso (Somalia), BSA",
    "Bratislava (Eslovaquia), BTS",
    "Brazzaville (Congo), BZV",
    "Buchanan (Liberia), UCN",
    "Bujumbura (Burundi), BJM",
    "Cabo Haitiano (Haití), CAP",
    "Cairo (Egipto), CAI",
    "Calicut (India), CCJ",
    "Campbell Island (Nueva Zelanda), NZC",
    "Cancún (México), CUN",
    "Cartago (Túnez), TBJ",
    "Chachapoyas (Perú), CHH",
    "Chileka (Malaui), BLZ",
    "Chisinau (Moldavia), KIV",
    "Conakry (Guinea), CKY",
    "Cotonú (Benín), COO",
    "Da Nang (Vietnam), DAD",
    "Dakhla (Sahara Occidental), VIL",
    "Daman (India), NMB",
    "Djerba (Túnez), DJE",
    "Doha (Catar), DOH",
    "Dubai (Emiratos Árabes Unidos), DXB",
    "Dublín (Irlanda), DUB",
    "Durango (México), DGO",
    "East London (Sudáfrica), ELS",
    "Eindhoven (Países Bajos), EIN",
    "Entebbe (Uganda), EBB",
    "Espargos (Cabo Verde), SID",
    "Ereván (Armenia), EVN",
    "Estambul (Turquía), IST",
    "Estrasburgo (Francia), SXB",
    "Estación Whakatane (Nueva Zelanda), WHK",
    "Faisalabad (Pakistán), LYP",
    "Funchal (Portugal), FNC",
    "Funchal (Portugal), FNC",
    "Georgetown (Guyana), GEO",
    "Gitega (Burundi), GID",
    "Goma (Congo), GOM",
    "Gonaïves (Haití), GON",
    "Granada (Nicaragua), GND",
    "Greymouth (Nueva Zelanda), GMN",
    "Gustavus (Estados Unidos), GST",
    "Ha'apai (Tonga), HPY",
    "Habruta (Etiopía), HOA",
    "Haifa (Israel), HFA",
    "Hargeisa (Somalia), HGA",
    "Havana (Cuba), HAV",
    "Hayman Island (Australia), HIS",
    "Hermosillo (México), HMO",
    "Hiroshima (Japón), HIJ",
    "Hoedspruit (Sudáfrica), HDS",
    "Hong Kong (Hong Kong), HKG",
    "Honolulu (Estados Unidos), HNL",
    "Honiara (Islas Salomón), HIR",
    "Ibadán (Nigeria), IBA",
    "Iguazú (Argentina), IGR",
    "Isla Majuro (Islas Marshall), MAJ",
    "Isla Mataveri (Chile), IPC",
    "Isla Nadi (Fiyi), NAN",
    "Islas Andamán (India), IXZ",
    "Islas Australes (Polinesia Francesa), AAA",
    "Islas Cook (Islas Cook), RAR",
    "Islas Cook (Islas Cook), ICI",
    "Islas Malvinas (Islas Malvinas), FIH",
    "Islas Orcadas del Sur (Antártida), SOW",
    "Islas Pitcairn (Islas Pitcairn), PIS",
    "Islas Qeshm (Irán), GSM",
    "Islas Saipán (Islas Marianas del Norte), GSN",
    "Islas Seychelles (Seychelles), PRI",
    "Islas Sunda (Indonesia), PKY",
    "Isola Bella (Italia), ISR",
    "Istanbul (Turquía), IST",
    "Istanbul (Turquía), SAW",
    "Ixtapa (México), ZIH",
    "Jacksonville (Estados Unidos), JAX",
    "Jersey (Islas del Canal), JER",
    "Jubba (Somalia), JIB",
    "Kabul (Afganistán), KBL",
    "Kampala (Uganda), EBB",
    "Kanifing (Gambia), BJL",
    "Kariba (Zimbabue), KAB",
    "Kavieng (Papúa Nueva Guinea), KVG",
    "Kavieng (Papúa Nueva Guinea), KVG",
    "Keflavik (Islandia), KEF",
    "Kempton Park (Sudáfrica), JNB",
    "Khujand (Tayikistán), LBD",
    "Kigali (Ruanda), KGL",
    "Kigoma (Tanzania), TKQ",
    "Kimberley (Sudáfrica), KIM",
    "Kinshasa (Congo), FIH",
    "Kish (Irán), KIH",
    "Kolkata (India), CCU",
    "Kota Bharu (Malasia), KBR",
    "Kuala Lumpur (Malasia), KUL",
    "Kuala Lumpur (Malasia), SZB",
    "Kuala Terengganu (Malasia), TGG",
    "Kuantan (Malasia), KUA",
    "Kuching (Malasia), KCH",
    "Kuching (Malasia), SZB",
    "Kuwait City (Kuwait), KWI",
    "Kwekwe (Zimbabue), KWE",
    "La Unión (El Salvador), SAL",
    "Ladysmith (Sudáfrica), LAY",
    "Lahore (Pakistán), LHE",
    "Lajes (Portugal), TER",
    "Lambarene (Gabón), LBQ",
    "Larnaca (Chipre), LCA",
    "Las Palmas (España), LPA",
    "Las Piedras (Uruguay), LAP",
    "Latakia (Siria), LTK",
    "Libreville (Gabón), LBV",
    "Lilongwe (Malaui), LLW",
    "Lima (Perú), LIM",
    "Lisboa (Portugal), LIS",
    "Lisboa (Portugal), LIS",
    "Livingstone (Zambia), LVI",
    "Lodwar (Kenia), LOK",
    "Lome (Togo), LFW",
    "Londres (Reino Unido), LGW",
    "Londres (Reino Unido), LHR",
    "Lubumbashi (Congo), FBM",
    "Luqa (Malta), MLA",
    "Luxor (Egipto), LXR",
    "Lusaka (Zambia), LUN",
    "Lusambo (Zambia), LBO",
    "Machadodorp (Sudáfrica), MAH",
    "Mahe (Seychelles), PRI",
    "Majunga (Madagascar), MJN",
    "Malabo (Guinea Ecuatorial), SSG",
    "Malindi (Kenia), MYD",
    "Mamoudzou (Mayotte), DZA",
    "Mango (Costa de Marfil), MGO",
    "Maputo (Mozambique), MPM",
    "Marrakech (Marruecos), RAK",
    "Marsa Alam (Egipto), RMF",
    "Maseru (Lesoto), MSU",
    "Matabeleland North (Zimbabue), HWN",
    "Maseru (Lesoto), MSU",
    "Mazatlán (México), MZT",
    "Mbabane (Suazilandia), MTS",
    "Meixian (China), MXZ",
    "México (México), MEX",
    "Mfuwe (Zambia), MFU",
    "Miami (Estados Unidos), MIA",
    "Miandrivazo (Madagascar), ZVA",
    "Mildura (Australia), MQL",
    "Mineralnye Vody (Rusia), MRV",
    "Mombasa (Kenia), MBA",
    "Montevideo (Uruguay), MVD",
    "Moroni (Comoras), HAH",
    "Moroni (Comoras), YVA",
    "Mosca (Rusia), DME",
    "Moscu (Rusia), SVO",
    "Moshi (Tanzania), QSI",
    "Mpanda (Tanzania), MWN",
    "Mudgee (Australia), DGE",
    "Muharraq (Baréin), BAH",
    "Mumbai (India), BOM",
    "Murmansk (Rusia), MMK",
    "Muscat (Omán), MCT",
    "Mutare (Zimbabue), UTA",
    "Mwanza (Tanzania), MWZ",
    "Nacala (Mozambique), MNC",
    "Nadi (Fiyi), NAN",
    "Nairobi (Kenia), NBO",
    "Nairobi (Kenia), WIL",
    "Nairobi (Kenia), HKR",
    "Nakhchivan (Azerbaiyán), NAJ",
    "Nairobi (Kenia), NBO",
    "Namibe (Angola), MSZ",
    "Nampula (Mozambique), APL",
    "Nanjing (China), NKG",
    "Nanning (China), NNG",
    "Nanjing (China), NKG",
    "Nanning (China), NNG",
    "Navegantes (Brasil), NVT",
    "Ndola (Zambia), NLA",
    "Newark (Estados Unidos), EWR",
    "Nairobi (Kenia), NBO",
    "Nairobi (Kenia), HKR",
    "Nelkenbrecher Mountain (Antártida), NZO",
    "New Orleans (Estados Unidos), MSY",
    "Nouakchott (Mauritania), NKC",
    "Oaxaca (México), OAX",
    "Olavarría (Argentina), OVR",
    "Ondangwa (Namibia), OND",
    "Oran (Argelia), ORN",
    "Oranjemund (Namibia), OMD",
    "Oujda (Marruecos), OUD",
    "Oviedo (España), OVD",
    "Pago Pago (Samoa Americana), PPG",
    "Palma de Mallorca (España), PMI",
    "Papúa Nueva Guinea (Papúa Nueva Guinea), HKN",
    "Paraná (Argentina), PRA",
    "Pemba (Mozambique), POL",
    "Perth (Australia), PER",
    "Pietermaritzburg (Sudáfrica), PZB",
    "Playa Blanca (Panamá), BOC",
    "Playa del Carmen (México), PCM",
    "Pointe Noire (Congo), PNR",
    "Ponta Delgada (Portugal), PDL",
    "Port Elizabeth (Sudáfrica), PLZ",
    "Porto Alegre (Brasil), POA",
    "Porto de Galinhas (Brasil), PDP",
    "Porto Seguro (Brasil), BPS",
    "Porto Velho (Brasil), PVH",
    "Pristina (Kosovo), PRN",
    "Puerto Barrios (Guatemala), PBR",
    "Puerto Deseado (Argentina), PUD",
    "Puerto Moresby (Papúa Nueva Guinea), POM",
    "Punta Cana (República Dominicana), PUJ",
    "Punta del Este (Uruguay), PDP",
    "Punta Gorda (Belice), PND",
    "Puqi (China), HCJ",
    "Qaisumah (Arabia Saudita), AQI",
    "Qeshm (Irán), GSM",
    "Quelimane (Mozambique), UEL",
    "Querétaro (México), QRO",
    "Quito (Ecuador), UIO",
    "Rabat (Marruecos), RBA",
    "Ramón Castilla (Perú), CHM",
    "Ramón Villeda Morales (Honduras), SAP",
    "Recalada a Bahía Blanca (Argentina), RBB",
    "Río Branco (Uruguay), RVY",
    "Río Branco (Uruguay), RVY",
    "Río Cuarto (Argentina), RCU",
    "Río de Janeiro (Brasil), GIG",
    "Río Gallegos (Argentina), RGL",
    "Río Grande (Argentina), RGA",
    "Río Hato (Panamá), RIH",
    "Río Hondo (Argentina), RHD",
    "Roatán (Honduras), RTB",
    "Roma (Italia), CIA",
    "Sal (Cabo Verde), SID",
    "Salamanca (México), SLW",
    "Salt Lake City (Estados Unidos), SLC",
    "Salvador (Brasil), SSA",
    "San Cristóbal (República Dominicana), STI",
    "San Fernando (Trinidad y Tobago), SFB",
    "San José (Costa Rica), SJO",
    "San Juan (Argentina), UAQ",
    "San Luis (Argentina), LUQ",
    "San Salvador (El Salvador), SAL",
    "Sana (Yemen), SAH",
    "Santa Clara (Cuba), SNU",
    "Santa Cruz (Bolivia), SRZ",
    "Santa Cruz (Bolivia), VVI",
    "Santa Cruz de Barahona (República Dominicana), BRX",
    "Santa Fe (Argentina), SFN",
    "Santa Rosa (Argentina), RSA",
    "Santo Domingo (República Dominicana), SDQ",
    "Sao Filipe (Cabo Verde), SFL",
    "Sao Luis (Brasil), SLZ",
    "São Paulo (Brasil), CGH",
    "São Paulo (Brasil), GRU",
    "São Paulo (Brasil), VCP",
    "Sechura (Perú), SEC",
    "Seuillet (Francia), UZI",
    "Sharjah (Emiratos Árabes Unidos), SHJ",
    "Sialkot (Pakistán), SKT",
    "Sicuani (Perú), SYC",
    "Simferopol (Ucrania), SIP",
    "Sindi (Etiopía), SXU",
    "Srinagar (India), SXR",
    "Strahan (Australia), SRN",
    "Suai (Timor Oriental), UAI",
    "Sweihan (Emiratos Árabes Unidos), ZDY",
    "Ta'if (Arabia Saudita), TIF",
    "Taba (Egipto), TCP",
    "Tabora (Tanzania), TBO",
    "Tabora (Tanzania), TBO",
    "Tallinn (Estonia), TLL",
    "Tambohorano (Madagascar), WTA",
    "Tampa (Estados Unidos), TPA",
    "Tanger (Marruecos), TNG",
    "Tanzania (Tanzania), QSI",
    "Tarawa (Kiribati), TRW",
    "Tari (Papúa Nueva Guinea), TIZ",
    "Tarimoro (México), TCB",
    "Tarija (Bolivia), TJA",
    "Tarso (Brasil), TSR",
    "Tasiilaq (Groenlandia), AGM",
]
  